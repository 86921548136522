import React from "react";
import {Button, Card, CardBody, Icon, List, StandardListCell} from "gd-react";
import GridDuck from "gridduck";
import NoItems from "../../components/noItems";
import getFormat from "../../services/formatter";
import AddEditCustomFieldModal from "../../modals/AddEditCustomField/AddEditCustomField";
import GenericLoader from "../../components/GenericLoader";
import {isMobile} from "react-device-detect";

class SiteCustomFields extends React.Component {

    constructor(props) {
        super(props);
        this.getList = this.getList.bind(this);
        this.openCustomFieldModal = this.openCustomFieldModal.bind(this)
        this.state = {
            customFields: null,
            showCustomFieldModal: false,
            loaded: true,
            updateRef: 1
        }
    }

    getList(retrievalParams) {
        retrievalParams.filters = retrievalParams.filters || [];
        retrievalParams.filters = retrievalParams.filters.concat([
            {
                field: 'siteId',
                value: [this.props.item.id]
            }
        ])
        return GridDuck.getCustomFields(retrievalParams);
    }

    openCustomFieldModal() {
        this.setState({showCustomFieldModal: true})
    }

    render() {
        let viewOnly = false;
        if ((this.props.item && this.props.item._permission === 'view') || isMobile) {
            viewOnly = true
        }
        let self = this
        let customFieldModal;
        if (self.state.showCustomFieldModal) {
            customFieldModal = <AddEditCustomFieldModal
                site={self.props.item}
                customFields={self.state.customFields}
                onUpdate={() => {
                    console.log('Update ref');
                    self.setState({updateRef: this.state.updateRef+1});
                }}
                onClose={() => {
                    self.setState({showCustomFieldModal: false});
                }}
                open={self.state.showCustomFieldModal}
            />
        }

        return (
            <div className='page'>
                {this.props.loaded ? <List
                    refreshRef={this.state.updateRef}
                    onEditClick={viewOnly ? null : self.openCustomFieldModal}
                    noitemelem={<NoItems
                        text={<div>{this.props.item.name} has no custom fields applied<br/><Button
                            label={'Apply Custom Fields'}
                            onClick={self.openCustomFieldModal}/>
                        </div>}
                    />}
                    columns={[
                        {
                            component: StandardListCell,
                            title: 'Icon',
                            field: 'icon',
                            maxWidth: '60px',
                            custom: function (item) {
                                console.log(item, 'item in custom field');
                                return <div>
                                    <Icon size={'14'} icon={item.icon} color={item.colour}/>
                                </div>
                            }
                        },
                        {
                            component: StandardListCell,
                            sortable: false,
                            title: 'Field',
                            field: 'customFieldName',
                            custom: function (value) {
                                return value.customFieldName
                            }
                        },
                        {
                            component: StandardListCell,
                            title: 'Value',
                            field: 'value',
                            maxWidth: 'none',
                            custom: function (value) {
                                let displayValue;
                                if (value.valueText !== null) {
                                    displayValue = value.valueText
                                } else if (value.valueBoolean !== null) {
                                    displayValue = value.valueBoolean ? 'True' : 'False'
                                } else if (value.valueTimestamp) {
                                    displayValue = getFormat('date')(value.valueTimestamp / 1000)
                                } else if (value.valueNumber) {
                                    displayValue = value.valueNumber
                                }
                                return displayValue
                            }
                        },
                    ]}
                    rowOnClick={viewOnly ? null : this.openCustomFieldModal}
                    getlist={this.getList}
                    filters={[]}
                /> : <GenericLoader/>}
                {customFieldModal}
            </div>
        );
    }
}

export default SiteCustomFields
