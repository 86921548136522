import React from "react";
import {
    Dropdown,
    GdAutocomplete,
    Button,
    Card,
    CardBody,
    CardHeader,
    Input,
    Toast,
    Icon,
    ScheduleTimeline
} from "gd-react";
import GridDuck from "gridduck";
import '../../styles/_layout.scss';
import SaveBar from "../../components/SaveBar/SaveBar";
import DeleteModal from "../../modals/Delete/DeleteModal";
import LocationForm from "../../components/LocationForm";
import TariffModal from '../../pages/TariffModal'
import GdModal from "../../components/GdModal/GdModal";
import GenericLoader from "../../components/GenericLoader";
import SiteOccupancyScheduleModal from "../../modals/SiteOccupancyScheduleModal/SiteOccupancyScheduleModal";
import * as _ from "lodash";
import history from "../../meta/history";
import {isMobile} from 'react-device-detect'

class SiteSettingsDetails extends React.Component {
    constructor(props) {
        super(props);
        this.handleClose = this.handleClose.bind(this);
        this.setSiteInfo = this.setSiteInfo.bind(this);
        this.onFormChange = this.onFormChange.bind(this);
        this.showGatewayVerificationModal = this.showGatewayVerificationModal.bind(this);
        this.hideGatewayVerificationModal = this.hideGatewayVerificationModal.bind(this);
        this.hasChanged = this.hasChanged.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
        this.cancelChanges = this.cancelChanges.bind(this);
        this.showDeleteModal = this.showDeleteModal.bind(this);
        this.openTariffModal = this.openTariffModal.bind(this)
        this.checkErrors = this.checkErrors.bind(this);
        this.generateUtilityTypeCell = this.generateUtilityTypeCell.bind(this)
        this.locationFormRef = React.createRef();
        this.errors = {};
        this.state = {
            showGatewayVerificationModal: false,
            showDeleteModal: false,
            site: {
                name: this.props.item.name || '',
                timezone: this.props.item.timezone || '',
                locationId: this.props.item.locationId || '',
                occupancyScheduleId: this.props.item.occupancyScheduleId || 'default'
            },
            occupancySchedule: this.props.item.occupancySchedule,
            disabled: true,
            applianceTypes: [],
        };
        let self = this;
        this.site = {
            name: this.props.item.name || '',
            timezone: this.props.item.timezone || '',
            locationId: this.props.item.locationId || '',
            occupancyScheduleId: this.props.item.occupancyScheduleId || 'default'
        };
    }

    async componentDidMount() {
        console.log(this.props.item, ' item in settings ')
        await this.setSiteInfo();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.item && this.props.item && prevProps.item.id !== this.props.item.id) {
            this.setSiteInfo();
        }
    }

    async setSiteInfo() {
        let self = this;

        let occupancySchedulesRes = await GridDuck.getOccupancySchedules({getAll: true});
        let account = await GridDuck.getAccount({id: GridDuck.userId})
        let orgDetails = await GridDuck.getOrganisation({id: account.organisationId});
        let schedule = occupancySchedulesRes.list.find((oc) => self.props.item.occupancyScheduleId === oc.id);

        console.log(schedule, ' : schedules ssss');
        console.log(self.props.item, ' : self.props.item ssss');

        console.log(orgDetails, ' : orgDetails');
        let defaultOccupancyScheduleId = orgDetails.defaultOccupancySchedule;
        let defaultOccupancySchedule;
        if (defaultOccupancyScheduleId) {
            defaultOccupancySchedule = await GridDuck.getOccupancySchedule({id: defaultOccupancyScheduleId});
        }
        console.log(defaultOccupancySchedule, ' : defaultOccupancySchedule');
        let occupancySchedules = occupancySchedulesRes.list.map(os => {
            return {
                id: os.id,
                value: os.id,
                title: os.name
            }
        });

        occupancySchedules.unshift({
            id: 'default',
            value: 'default',
            title: 'Default (' + (defaultOccupancySchedule ? defaultOccupancySchedule.name : 'None') + ')'
        });

        occupancySchedules.unshift({
            id: 'add_new',
            value: 'add_new',
            title: 'Create New',
            color: '#49b9c4',
            icon: 'FaPlus'
        });


        setTimeout(() => {
            this.setState({
                site: {
                    name: self.props.item.name,
                    timezone: self.props.item.timezone,
                    locationId: self.props.item.locationId,
                    occupancyScheduleId: self.props.item.occupancyScheduleId || 'default'
                },
                defaultOccupancySchedule: defaultOccupancySchedule,
                occupancySchedule: schedule ? schedule.schedule : null,
                occupancySchedules: occupancySchedules,
                allOccupancySchedules: occupancySchedulesRes.list,
                reload: false,
                hasChanged: false
            })
        }, 100)
    }

    onFormChange(val) {
        if (val.target.name === 'occupancyScheduleId' && val.target.value === 'add_new') {
            val.stopPropagation();
            val.preventDefault();
            return this.setState({showOccupancyScheduleModal: true})
        }
        this.state.site[val.target.name] = val.target.value;
        this.state.hasChanged = true;
        this.setState({site: this.state.site});
    }

    showGatewayVerificationModal() {
        this.setState({showGatewayVerificationModal: true});
        let self = this;
        return GridDuck.getGateways({
            filters: [
                {
                    field: "siteId",
                    value: this.props.item.id
                }
            ]
        }).then(function (gatewayList) {
            self.setState({
                ethernetMacs: gatewayList.list.map(gW => gW.ethernetMac).join(", "),
                wlanMacs: gatewayList.list.map(gW => gW.wlanMac).join(", ")
            });
        })
    }

    hideGatewayVerificationModal() {
        this.setState({showGatewayVerificationModal: false});
    }

    hasChanged() {
        // let self = this;
        // let hasChanged = false;
        // console.log('self.site, self.state.site : ', self.site, self.state.site);
        // if (self.site && self.state.site) {
        //     Object.entries(self.site).map(([key, value]) => {
        //         let updatedKey = key.replace(/^_+/i, '');
        //         if ((updatedKey in self.state.site) && self.state.site[updatedKey] !== value) {
        //             hasChanged = true;
        //         }
        //     })
        // }
        // if (this.state.hasChanged) hasChanged = true;
        return this.state.hasChanged;
    }

    cancelChanges() {
        this.setState({reload: true}, () => {
            this.setSiteInfo();
        });
    }

    showDeleteModal() {
        console.log('show delete modal clicked')
        this.setState({showDeleteModal: true})
    }

    async saveChanges(e, override) {
        this.setState({triedToSave: true})
        let locationErrors = this.locationFormRef.current.checkErrors(true);

        console.log(locationErrors, ' location errors')

        console.log(this.errors, ' this.errors site settings')
        if (Object.keys(this.errors).length > 0 || Object.keys(locationErrors).length > 0) return Promise.reject();


        if (e) {
            e.preventDefault();
        }

        let self = this;
        let updateObject = {};
        console.log("keys");
        console.log(self.props.item);
        console.log(Object.keys(this.state.site));
        Object.keys(this.state.site).forEach(function (key) {
            if (self.state.site[key] !== self.props.item[key]) {
                if (key !== 'occupancyScheduleId' || !(self.state.site[key] === 'default' && self.props.item[key] === null)) {
                    console.log(key, self.state.site[key], self.props.item[key]);
                    updateObject[key] = self.state.site[key];
                }
            }
        })
        console.log("update object", updateObject);
        console.log(override, ' : override');
        if (override && override.occupancyScheduleId) {
            updateObject.occupancyScheduleId = override.occupancyScheduleId;
        }
        let locationRes = await this.locationFormRef.current.createNewLocation();
        if (this.props.item.locationId !== locationRes.id) {
            updateObject.locationId = locationRes.id;
        }
        console.log("Update", updateObject);
        await this.props.item.set(updateObject);
        self.setState({open: true, reload: true}, () => {
            self.setSiteInfo();
        });
    }

    handleClose() {
        this.setState({open: false})
    }

    checkErrors() {
        let errors = {};
        if (!this.state.site.name.length) errors['name'] = true;
        this.errors = errors;
    }


    openTariffModal(tariff) {
        console.log(tariff, ' : tariff being opened site settings');
        this.setState({openTariffModal: true, tariff: tariff})
    }

    generateUtilityTypeCell(t) {
        let icon = '';
        let color = ''
        let name = '';

        if (t.utilityType === 'DELIVERED_GAS_SPEND') {
            icon = 'FaFire';
            color = '#D768F2';
            name = 'Gas'
        }
        if (t.utilityType === 'DELIVERED_WATER_SPEND') {
            icon = 'FaWater';
            color = '#00C3EE';
            name = 'Water'
        }
        if (t.utilityType === 'DELIVERED_WATER_HOT_SPEND') {
            icon = 'FaWater';
            color = '#f52ec0';
            name = 'Hot Water'
        }
        if (t.utilityType === 'DELIVERED_SPEND') {
            icon = 'FaBolt';
            color = '#FFCA00';
            name = 'Electricity'
        }
        if (!t.utilityType) {
            icon = 'FaQuestionCircle';
            color = 'gd-grey';
        }
        return (<div className={'row'} style={{height: '30px', display: 'flex', alignItems: 'center'}}>
            <div style={{
                height: '20px',
                width: '20px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: color,
                borderRadius: '10px'
            }}>
                <Icon size={'10'} icon={icon} color={'white'}/>
            </div>
            <span style={{marginLeft: '10px'}}>{name}</span>
        </div>)
    }

    render() {

        this.checkErrors();
        let modalVerificationContent;
        if (this.state.showGatewayVerificationModal && this.state.ethernetMacs) {
            modalVerificationContent = (
                <div>
                    <strong>Ethernet MAC: </strong>
                    {this.state.ethernetMacs}
                    <br/>
                    <strong>WiFi MAC: </strong>
                    {this.state.wlanMacs}
                    <br/></div>
            )
        } else {
            modalVerificationContent = (
                <div>
                    Loading...
                </div>
            )
        }
        let settingsEditCard;
        let customFieldsCard, customFieldRows = [];
        let advancedCard;
        let tariffsCard;
        let button;
        let schedule;
        console.log(this.state.site, ' : site');
        if (this.state.allOccupancySchedules) {

            if (!this.state.allOccupancySchedules.find(aos => aos.id === this.props.item.occupancyScheduleId)) {
                this.state.allOccupancySchedules.push({
                    id: this.props.item.occupancyScheduleId,
                    name: this.props.item.occupancyScheduleName,
                    schedule: this.props.item.occupancySchedule
                });
                this.state.occupancySchedules.push({
                    id: this.props.item.occupancyScheduleId,
                    value: this.props.item.occupancyScheduleId,
                    title: this.props.item.occupancyScheduleName
                });
            }
            console.log(this.state.allOccupancySchedules, ' : this.state.allOccupancySchedules');
            let activeSchedule = this.state.allOccupancySchedules.find((aos) => aos.id === this.state.site.occupancyScheduleId);
            console.log(activeSchedule, ' : activeSchedule');
            console.log(this.state.defaultOccupancySchedule, ' : this.state.defaultOccupancySchedule');
            if (activeSchedule) {
                schedule = activeSchedule.schedule;
            }
            if (this.state.defaultOccupancySchedule) {
                schedule = this.state.defaultOccupancySchedule.schedule;
            }

            // if (!schedule && this.state.site.occupancyScheduleId) {
            //     schedule = this.state.site.occupancyScheduleId;
            //     if (!this.state.occupancySchedules.find(os => os.id === this.state.site.occupancyScheduleId)) {
            //         this.state.occupancySchedules.push({
            //             id: this.props.item.occupancyScheduleId,
            //             value: this.props.item.occupancyScheduleId,
            //             title: this.props.item.occupancyScheduleName
            //         });
            //     }
            // }

            if (schedule) {
                schedule = _.map(schedule, s => _.mapKeys(s, (v, k) => _.camelCase(k)))
            }

        }

        let footerSaveBar = this.hasChanged() ?

            <div className={'detail-content'}>
                <SaveBar onSaveClick={this.saveChanges} onCancelClick={this.cancelChanges}/></div>
            : '';

        if (this.state.site && !this.state.reload) {
            settingsEditCard = (
                <Card>
                    <CardHeader title={'General'}/>
                    <CardBody>
                        <form onSubmit={this.handleUpdate}>
                            <Input top='true' label={'Name'} error={this.errors['name'] && this.state.triedToSave}
                                   required name={'name'} value={this.state.site.name}
                                   onKeyPress={(event) => {
                                       if (event.key === 'Enter') this.saveChanges();
                                   }}
                                   disabled={this.props.item._permission !== 'admin' && this.props.item._permission !== 'edit'}
                                   onChange={this.onFormChange}/>
                            <br/>
                            <LocationForm
                                disabled={this.props.item._permission !== 'admin' && this.props.item._permission !== 'edit'}
                                ref={this.locationFormRef}
                                value={this.props.item.locationId}
                                onLocationIdChange={(val) => this.setState({
                                    locationId: val,
                                    hasChanged: true
                                })}
                                onNewLocationNameChange={(val) => this.setState({
                                    newLocationName: val,
                                    hasChanged: true
                                })}
                                onNewLocationTimezoneChange={(val) => this.setState({
                                    newLocationTimezone: val,
                                    hasChanged: true
                                })}
                                onNewLocationMetadataChange={(val) => this.setState({
                                    newLocationMetaData: val,
                                    hasChanged: true
                                })}/>
                            <div className={'settings-row'}>
                                {this.state.occupancySchedules ? <Dropdown
                                    name={'occupancyScheduleId'}
                                    value={this.state.site.occupancyScheduleId}
                                    fixeditems={this.state.occupancySchedules}
                                    disabled={this.props.item._permission !== 'admin' && this.props.item._permission !== 'edit'}
                                    placeholder={'Occupancy Schedule'}
                                    label="Occupancy Schedule"
                                    onChange={(var1, var2) => this.onFormChange(var1, var2)}
                                /> : <GenericLoader/>}
                            </div>
                            {!isMobile ? <div className={'row reverse'}>
                                <p className={'link'}
                                   onClick={() => history.push('/settings/organisation/occupancy-schedules')}>Manage
                                    Schedules</p>
                            </div> : null}
                            <div className={'row'}>
                                {/*defaultOccupancySchedule*/}
                                {schedule ? <ScheduleTimeline
                                    rules={schedule}
                                    actions={[{
                                        id: -1,
                                        name: 'Active',
                                        color: '#49c570'
                                    }]}
                                    inactiveState={'inactive'}/> : null}
                            </div>
                        </form>
                    </CardBody>
                </Card>
            )


            advancedCard = (
                <Card>
                    <CardHeader title={'Advanced'}/>
                    <CardBody>
                        <p className={"verify-text"}>If you are looking to install on a secure network you will need to
                            give the buildings IT teams the information shown when clicking the button below.</p>
                        <br/>
                        <Button label={'Verify site hubs'} onClick={this.showGatewayVerificationModal}/>
                        <br/>
                        <br/>
                        {this.props.item._permission === 'admin' ?
                            <Button label={'Delete Site'} color={'gd-red'} onClick={this.showDeleteModal}/> : ''}
                    </CardBody>
                </Card>
            )
        }

        let verificationModal = <GdModal
            open={this.state.showGatewayVerificationModal}
            onClose={this.hideGatewayVerificationModal}
            title="Verify Site Hubs">
            <CardBody>
                <p className={"gateway-verifying-modal-text"}>Copy the text below into an email for the IT
                    support of the secure building you wish to connect the hubs(s) to.</p>
                <div className={"mac-address-email-text"}>
                    Access Control Request: Please authorise the following GridDuck energy management
                    hardware
                    for access to your network.
                    <br/><br/>
                    {modalVerificationContent}
                </div>
            </CardBody>
        </GdModal>

        return (
            <div className={"page narrow not-flex grey"}>

                <div className={'column top'}>
                    {footerSaveBar}
                    <div className={'detail-content'}>
                        {this.props.loaded ? settingsEditCard : <GenericLoader/>}
                    </div>
                </div>
                <div className={'column'}>

                    <div className={'detail-content'}>
                        {this.props.item._permission === 'admin' ? advancedCard : null}
                    </div>
                    {footerSaveBar}
                </div>
                {verificationModal}

                <DeleteModal
                    itemType={'site'}
                    item={this.props.item}
                    open={this.state.showDeleteModal}
                    onClose={() => this.setState({showDeleteModal: false})}/>
                {this.state.openTariffModal ? <TariffModal
                    item={this.state.tariff}
                    open={this.state.openTariffModal}
                    onClose={() => this.setState({openTariffModal: null, tariff: null})}/> : null}
                <Toast onClose={this.handleClose} message={"Save Successful"}
                       anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                       open={this.state.open}/>
                <SiteOccupancyScheduleModal
                    onSave={(msg, item, e) => this.saveChanges(e, {occupancyScheduleId: item.id})}
                    onClose={() => this.setState({showOccupancyScheduleModal: false})}
                    open={this.state.showOccupancyScheduleModal}/>

            </div>
        )
    }
}

export default SiteSettingsDetails;
