import React from "react";
import GridDuck from "gridduck";
import history from "../meta/history";
import {Icon, List, StandardListCell} from "gd-react";
import NoItems from "../components/noItems";
import AddEditCustomFieldTypeModal from "../modals/AddEditCustomField/AddEditCustomFieldType";

class CustomFieldListPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            update: 0
        };
        this.getList = this.getList.bind(this);
        this.openCustomFieldTypeModal = this.openCustomFieldTypeModal.bind(this);
    }

    openCustomFieldTypeModal(field) {
        this.setState({showCustomFieldTypeModal: field});
        history.push('/settings/organisation/custom-fields?id=' + field.id);
    }

    getList(retrievalParams) {
        return GridDuck.getCustomFieldTypes(retrievalParams);
    }

    componentDidMount() {
        let self = this;
        let urlParams = new URLSearchParams(window.location.search);
        let customFieldTypeId = urlParams.get('id');
        if (customFieldTypeId) {
            GridDuck.getCustomFieldTypes({filters: [{field: 'id', value: customFieldTypeId}]}).then((field) => {
                self.setState({showCustomFieldTypeModal: field.list[0]});
            });
        }
    }

    render() {
        return (
            <div className={"page white"}>
                <List
                    noitemelem={<NoItems text={'You haven\'t created any custom fields yet'}
                                         createtext={'Create Custom Field'} modal={AddEditCustomFieldTypeModal}/>}
                    columns={[
                        {
                            component: StandardListCell,
                            title: 'Icon',
                            field: 'icon',
                            maxWidth: '60px',
                            custom: function (item) {
                                return <div><Icon size={'14'} icon={item.icon || 'FaQuestion'} color={item.colour}/>
                                </div>
                            }
                        },
                        {component: StandardListCell, bold: true, title: 'Name', field: 'name'},
                        {component: StandardListCell, title: 'Field Type', field: 'fieldType'},
                        {component: StandardListCell, title: 'Access Level', field: '_permission', maxWidth: 'none'},
                    ]}
                    getlist={this.getList}
                    rowOnClick={this.openCustomFieldTypeModal}/>
                {this.state.showCustomFieldTypeModal ? <AddEditCustomFieldTypeModal onClose={() => {
                    this.setState({showCustomFieldTypeModal: null});
                    history.push('/settings/organisation/custom-fields');
                }} open={!!this.state.showCustomFieldTypeModal} item={this.state.showCustomFieldTypeModal}/> : null}
            </div>
        )
    }
}

export default CustomFieldListPage;
