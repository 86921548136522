import {Button} from "gd-react";
import React from "react";
import {Redirect} from 'react-router-dom';
// import GridDuck from 'gridduck';
import cookie from 'react-cookies'

class NoItems extends React.Component {
    constructor(props) {
        super(props);
        this.state = {openModal: false};
    }

    render() {
        let AddModal = this.props.modal;
        return (
            <div className={'no-items-container-wrapper'}>
                <div className={'no-items-container'}>
                    <h1 className={'no-items-text'}>{this.props.text}</h1>
                    <div className={'row center'}>
						{this.props.disabled || !this.props.createtext ? '' : (<Button onClick={() => this.props.onclickfunc ? this.props.onclickfunc() : this.setState({openModal: true})} label={this.props.createtext}/>)}
                    </div>
                    {this.state.openModal ? <AddModal open={this.state.openModal}
                                                      onClose={() => this.setState({openModal: false})}/> : ''}
                </div>
            </div>
        )
    }
}

export default NoItems;
