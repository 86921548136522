import "./Legend.scss";
import React from "react";
import {Button, DateTimeRangePicker, Dropdown, Icon, Tooltip} from "gd-react";
import moment from "moment-timezone";
import LegendRange from "./LegendRange";

class MobileLegendRange extends LegendRange {

    render() {
        let dateSelect;
        let compareSelect;
        let dateSelectValue = [new Date(this.state.start * 1000), new Date(this.state.end * 1000)];
        let customSelect;
        if (this.state.dateSelection === 'custom') {
            customSelect = (
                <DateTimeRangePicker
                    cleanable={false} key={'selector'} placement={'auto'} onChange={this.rangeUpdated}
                    nolabel={true} value={dateSelectValue}/>);
        }
        if (this.props.graph === 'bar' || this.props.graphType === 'hour_of_day' || this.props.graphType === 'breakdown' || this.props.graphType === 'day_of_week') {
            if (!this.state.isComparing) {
                compareSelect = (<Button additionalclasses={'mini'} label={"Compare to..."}
                                         onClick={this.setInitialComparison}/>);
            } else {
                compareSelect = [
                    (<span key={'ctl'} className={'compare-to-label'}>Compare to</span>),
                    (<div key={'ctl2'}
                          style={{alignItems: 'center', overflow: 'hidden', width: '100%', position: 'relative'}}>
                        <Dropdown key={"dropdown-date"} nolabel='true' onChange={this.compareDateSelection}
                                  fixeditems={this.state.comparisonOptions.filter((pv) => pv.comparingTo === this.state.dateSelection || pv.value === 'custom')}
                                  value={this.state.compareDateSelection}/>
                        <div className={'cancel-compare'}
                             style={{position: 'absolute', right: '34px', top: '22px', zIndex: 5}}><Icon
                            color={'gd-red'} size={'12'} icon={'FaTimes'} onIconClick={this.cancelComparing}/></div>
                    </div>)
                ]
                if (this.state.compareDateSelection === 'custom') {
                    compareSelect.push((
                        <DateTimeRangePicker
                            key={'ctl3'}
                            cleanable={false} placement={'auto'} placeholder={'Date Range'}
                            value={this.state.compareSelectValue}
                            onClean={this.cancelComparing}
                            onChange={this.compareRangeUpdated} nolabel={true}
                            fixedDuration={(this.state.end - this.state.start)}/>
                    ))
                }
            }
        }
        let dateSelectRange = [
            {
                value: 'today_so_far',
                title: 'Today so far'
            },
            {
                value: 'yesterday',
                title: 'Yesterday'
            },
            {
                value: 'week_so_far',
                title: 'Week So Far'
            },
            {
                value: 'last_week',
                title: 'Last Week'
            },
            {
                value: 'month_so_far',
                title: 'Month So Far'
            },
            {
                value: 'last_month',
                title: 'Last Month'
            },
            {
                value: 'year_so_far',
                title: 'Year So Far'
            },
            {
                value: 'last_year',
                title: 'Last Year'
            },
            {
                value: 'custom',
                title: 'Custom'
            }
        ]

        if ((this.props.category !== 'Gas' && this.props.category !== 'Water' || this.props.category !== 'Hot Water') && !this.props.isAll && this.props.graphType !== 'heatmap') {
            dateSelectRange.unshift({
                value: 'last_15_mins',
                title: 'Last 15 mins'
            });
        }

        if (this.props.isAll) {
            dateSelectRange = dateSelectRange.slice(1);
        }
        dateSelect = (
            // <span>Date Range</span>
            <Dropdown key={"dropdown-date"} nolabel='true' onChange={this.dateSelection}
                      fixeditems={dateSelectRange} value={this.state.dateSelection}/>
            // {
            //     customSelect
            // }
            // {compareSelect}
        )
        let granularity;
        let granularityOptions = [];

        if (this.state.end - this.state.start > 60 * 60) {
            granularityOptions.push({
                    value: 60 * 30,
                    title: 'Half Hour',
                },
                {
                    value: 60 * 60,
                    title: 'Hour',
                })
        }
        if (this.state.end - this.state.start < 60 * 60) {
            granularityOptions.unshift({
                value: 30,
                title: '30 Seconds'
            })
        }
        if (this.state.end - this.state.start > 60 * 60 * 24) {
            granularityOptions.push(
                {
                    value: 60 * 60 * 24,
                    title: 'Day'
                }
            );
        }
        if (this.state.end - this.state.start > 60 * 60 * 24 * 7) {
            granularityOptions.push(
                {
                    value: 60 * 60 * 24 * 7,
                    title: 'Week'
                }
            );
        }
        if (this.state.end - this.state.start > 60 * 60 * 24 * 31) {
            granularityOptions.push(
                {
                    value: 'monthly',
                    title: 'Month'
                }
            );
        }
        if (this.props.graph === 'bar' && this.props.graphType !== 'day_of_week' && this.props.graphType !== 'hour_of_day' && this.props.graphType !== 'breakdown') {
            granularity = (
                    <Dropdown
                        value={this.state.granularity}
                        placeholder={'Granularity'}
                        key={"granularity-dropdown"} nolabel='true' onChange={this.granularitySelection}
                        fixeditems={granularityOptions}/>)
        }

        return (
            <React.Fragment>
                <div className={'row-dropdowns-wrapper'}>
                    <div className={'row row-dropdowns'}>
                        {this.props.picker} {dateSelect} {granularity}
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default MobileLegendRange;
