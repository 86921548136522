import "./Legend.scss";
import React from "react";
import Legend from "./Legend";
import LegendRange from "./LegendRange";
import MobileLegendRange from "./MobileLegendRange";

class MobileLegend extends Legend {

    render() {
        let legendItems = [];
        let self = this;
        if (this.props.items) {
            let items = this.props.items;
            if (this.props.data) {
                items.sort(function (itemA, itemB) {
                    let itemDataA = self.props.data.data.find(d => d.name === itemA.name);
                    let itemDataB = self.props.data.data.find(d => d.name === itemB.name);
                    if (itemDataA && itemDataB) {
                        return itemDataB.rawVal - itemDataA.rawVal
                    } else {
                        return 0;
                    }
                })
            }
            items.forEach(function (item) {
                legendItems.push(
                    self.createLegendItem(item)
                );
            })
        }
        let className = "graph-legend row";
        if (this.props.highlighted.length > 0) {
            className += " highlighted";
        }
        let tooltipDate;
        if (this.props.data && this.props.data.date) {
            tooltipDate = (<div className={"timestamp"}>
                <span>{this.props.data.locked ? 'Locked to' : 'Hovering over'}</span>
                <span className={"bold"}>{this.props.data.date}</span>
            </div>)
        }
        let tooltipDateCompare;
        if (this.props.data && this.props.data.compareDate) {
            tooltipDateCompare = (<div className={"timestamp"} style={{marginTop: '6px'}}>
                <span>Comparing to</span>
                <span className={"bold"}>{this.props.data.compareDate}</span>
            </div>)
        }

        return (
            <div
                className={'legend-wrapper' + (this.props.disabled ? ' disabled' : '') + (this.props.zoomData ? ' zoomed' : '') + ((this.props.data && this.props.data.locked) ? ' locked' : '') + (this.state.dateSelection === 'custom' || this.state.isComparing ? ' with-custom' : '')}>
                <MobileLegendRange
                    disabled={this.props.disabled}
                    picker={this.props.picker}
                    dRString={this.props.dRString}
                    cRString={this.props.cRString}
                    granularity={this.props.granularity}
                    category={self.props.category}
                    onHover={this.props.onHover}
                    onHide={this.props.onHide}
                    resetZoom={this.props.resetZoom}
                    zoomData={this.props.zoomData}
                    defaultSelected={this.props.defaultSelected}
                    graph={this.props.graph}
                    graphType={this.props.graphType}
                    start={this.props.start}
                    timezone={this.props.timezone}
                    end={this.props.end}
                    set={this.props.set}
                    compareStart={this.props.compareStart}
                    compareEnd={this.props.compareEnd}
                    onIsComparingChange={this.onIsComparingChange}
                    onSelectionTypeChange={this.onSelectionTypeChange}
                    isAll={this.props.isAll}
                />

                {/*<div className={'graph-legend-wrapper'}>*/}
                {/*    {this.props.data ? this.props.data.date : ''}*/}
                {/*</div>*/}
                <div className={'graph-legend-wrapper copy-inside'}>
                    {this.props.data ? this.props.data.date : '--/--/---- --:--'}
                </div>
                <div className={'graph-legend-wrapper'}>
                    <div className={className}>
                        {legendItems}
                    </div>
                </div>

                {/*<div className={'graph-legend-wrapper'}>*/}
                {/*    {tooltipDate}*/}
                {/*    {tooltipDateCompare}*/}
                {/*</div>*/}
            </div>
        )
    }
}

export default MobileLegend;
